import React, { FC } from 'react';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { useSearchURL } from '../context/searchStore';
import { WWWCompanyDocument } from '../common/documents/WWWCompanyDocument';
import { formatMinMax } from '../common/constleUtils';
import { parseISO, isValid, format } from 'date-fns';
import { useRouter } from 'next/router';

// TODO: This should come from env...
/** Hostname used by SSR */
export const defaultHostname = 'www.constle.fi';

const generateLocaleURL = (asPath: string, locale: string) => {
  const pathname = asPath.split('?')[0];

  if (typeof window === 'undefined') {
    return `https://${defaultHostname}/${locale}${pathname}`;
  } else {
    return `https://${window.location.hostname}/${locale}${pathname}`;
  }
};

/**
 * This will get the "primary" URL of the page, it should always be prefixed with the default locale
 * @see https://developers.google.com/search/blog/2010/09/unifying-content-under-multilingual
 */
const useCanonicalUrl = () => {
  const { defaultLocale, asPath } = useRouter();
  return generateLocaleURL(asPath, defaultLocale);
};

/**
 * This will generate all "alternate" URLs for the current page, these are URLs prefixed with all non-default locales
 * @see https://developers.google.com/search/blog/2010/09/unifying-content-under-multilingual
 */
const useAlternateTags = () => {
  const { locales, defaultLocale, asPath } = useRouter();

  return locales
    .filter((locale) => locale !== defaultLocale)
    .map((locale) => <link rel="alternate" hrefLang={locale} href={generateLocaleURL(asPath, locale)} />);
};

export const FrontPage: FC = () => {
  const intl = useIntl();
  const url = useCanonicalUrl();
  const alternates = useAlternateTags();

  const structureData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${url}search?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  };

  const title = intl.formatMessage({
    id: 'frontPage.seo.title',
    defaultMessage: 'Constle - Kaikki rakentajat ulottuvillasi',
  });

  const description = intl.formatMessage({
    id: 'frontPage.seo.description',
    defaultMessage:
      'Constle on rakennusalan ammattilaisille kehitetty helppokäyttöinen työkalu, jonka avulla löydät tarvitsemasi yhteistyökumppanit hetkessä.',
  });

  return (
    <Head>
      {/* Title */}
      <title>{title}</title>

      {/* Description */}
      <meta name="description" content={description} />

      {/* This tells Google that / is the URL that should be indexed for this page, this is required for the search box in Google Search */}
      <link rel="canonical" href={url} />
      {alternates}

      {/* Structured data */}
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structureData) }} />
    </Head>
  );
};

export const SearchPage: FC = () => {
  const intl = useIntl();
  const url = useCanonicalUrl();
  const alternates = useAlternateTags();
  const { queryText } = useSearchURL();

  const emptySearch = intl.formatMessage({
    id: 'searchPage.seo.emptySearch',
    defaultMessage: 'Haku',
  });

  const title = intl.formatMessage(
    {
      id: 'searchPage.seo.title',
      defaultMessage: '{search} | Constle',
    },
    {
      search: queryText || emptySearch,
    }
  );

  const description = intl.formatMessage({
    id: 'searchPage.seo.description',
    defaultMessage:
      'Hakukone jolla löytää aina etsimänsä. Monipuoliset hakuominaisuudet kehitetty varta vasten ammattilaisten tarpeisiin. Tietokanta joka kattaa +100 000 rakennusalalla toimivan yrityksen tiedot.',
  });

  return (
    <Head>
      {/* Title */}
      <title>{title}</title>

      {/* Description */}
      <meta name="description" content={description} />

      {/* This tells Google that /search is the URL that should be indexed for this page, this is required for the search box in Google Search */}
      <link rel="canonical" href={url} />
      {alternates}
    </Head>
  );
};

export const PremiumPage: FC<{ title: string; description: string }> = ({ title, description }) => {
  const url = useCanonicalUrl();
  const alternates = useAlternateTags();

  return (
    <Head>
      {/* Title */}
      <title>{title}</title>

      {/* Description */}
      <meta name="description" content={description} />

      {/* This tells Google that /premium is the URL that should be indexed for this page */}
      <link rel="canonical" href={url} />
      {alternates}
    </Head>
  );
};

export const LoginPage: FC = () => {
  const intl = useIntl();
  const url = useCanonicalUrl();
  const alternates = useAlternateTags();

  const title = intl.formatMessage({
    id: 'loginPage.seo.title',
    defaultMessage: 'Kirjaudu sisään | Constle',
  });

  const description = intl.formatMessage({
    id: 'loginPage.seo.description',
    defaultMessage:
      'Kirjaudu sisään Constleen käyttämällä sähköpostiosoitetta ja salasanaa. Voit myös palauttaa unohtuneen salasanan tai rekisteröityä uudeksi käyttäjäksi.',
  });

  return (
    <Head>
      {/* Title */}
      <title>{title}</title>

      {/* Description */}
      <meta name="description" content={description} />

      {/* This tells Google that /login is the URL that should be indexed for this page */}
      <link rel="canonical" href={url} />
      {alternates}
    </Head>
  );
};

export const RegisterPage: FC = () => {
  const intl = useIntl();
  const url = useCanonicalUrl();
  const alternates = useAlternateTags();

  const title = intl.formatMessage({
    id: 'registerPage.seo.title',
    defaultMessage: 'Luo tili | Constle',
  });

  const description = intl.formatMessage({
    id: 'registerPage.seo.description',
    defaultMessage:
      'Luo tili Constleen. Ota alasi parhaat työkeikat ja kumppanit haltuun hakemalla ilmaiseksi Constlen avoimeen rakentajatietopankkiin.',
  });

  return (
    <Head>
      {/* Title */}
      <title>{title}</title>

      {/* Description */}
      <meta name="description" content={description} />

      {/* This tells Google that /login is the URL that should be indexed for this page */}
      <link rel="canonical" href={url} />
      {alternates}
    </Head>
  );
};

export const ContactUsPage: FC = () => {
  const intl = useIntl();
  const url = useCanonicalUrl();
  const alternates = useAlternateTags();

  const title = intl.formatMessage({
    id: 'contactUsPage.seo.title',
    defaultMessage: 'Ota yhteyttä | Constle',
  });

  const description = intl.formatMessage({
    id: 'contactUsPage.seo.description',
    defaultMessage:
      'Ota yhteyttä Constle-tukeen. Autamme mielellämme mahdollisissa kysymyksissä ja vikatilanteissa. Voit lähettää meille myös palautetta ja ideoita verkkosivustoon liittyen.',
  });

  return (
    <Head>
      {/* Title */}
      <title>{title}</title>

      {/* Description */}
      <meta name="description" content={description} />

      {/* This tells Google that /login is the URL that should be indexed for this page */}
      <link rel="canonical" href={url} />
      {alternates}
    </Head>
  );
};

export const PrivacyPolicyPage: FC = () => {
  const intl = useIntl();
  const url = useCanonicalUrl();

  const title = intl.formatMessage({
    id: 'privacyPolicyPage.seo.title',
    defaultMessage: 'Tietosuojaseloste | Constle',
  });

  const description = intl.formatMessage({
    id: 'privacyPolicyPage.seo.description',
    defaultMessage: 'Constle Construction Oy:n asiakas- ja markkinointiviestintärekisteri sekä palveluiden tietosuojaseloste',
  });

  return (
    <Head>
      {/* Title */}
      <title>{title}</title>

      {/* Description */}
      <meta name="description" content={description} />

      {/* This tells Google that /login is the URL that should be indexed for this page */}
      <link rel="canonical" href={url} />
    </Head>
  );
};

export const SettingsPage: FC = () => {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: 'settingsPage.seo.title',
    defaultMessage: 'Käyttäjätili | Constle',
  });

  return (
    <Head>
      {/* Title */}
      <title>{title}</title>
    </Head>
  );
};

export const AboutUsPage: FC = () => {
  const intl = useIntl();
  const url = useCanonicalUrl();

  const title = intl.formatMessage({
    id: 'aboutUsPage.seo.title',
    defaultMessage: 'Meistä | Constle',
  });

  const description = intl.formatMessage({
    id: 'aboutUsPage.seo.description',
    defaultMessage: 'Keitä me olemme? Mitä me teemme? Tältä sivulta se selviää!',
  });

  return (
    <Head>
      {/* Title */}
      <title>{title}</title>

      {/* Description */}
      <meta name="description" content={description} />

      {/* This tells Google that /login is the URL that should be indexed for this page */}
      <link rel="canonical" href={url} />
    </Head>
  );
};

export const CompanyPage: FC<{ company: WWWCompanyDocument }> = ({ company }) => {
  const intl = useIntl();
  const url = useCanonicalUrl();
  const alternates = useAlternateTags();

  const { name, marketingName, turnover, tol, dateEstablished } = company;

  const turnoverString = formatMinMax(turnover);

  const dateEstablishedDate = parseISO(dateEstablished);
  const dateEstablishedString = isValid(dateEstablishedDate) ? format(dateEstablishedDate, 'yyyy') : '';

  const title = intl.formatMessage(
    {
      id: 'companyPage.seo.title',
      defaultMessage: '{company} | Constle',
    },
    {
      company: marketingName || name,
    }
  );

  const tolText = intl.formatMessage(
    {
      id: 'companyPage.seo.tol',
      defaultMessage: 'Päätoimiala on {tol}.',
    },
    {
      tol: tol?.name || '',
    }
  );

  const turnoverText = intl.formatMessage(
    {
      id: 'companyPage.seo.turnover',
      defaultMessage: 'Liikevaihtohaarukka {turnover}.',
    },
    {
      turnover: turnoverString,
    }
  );

  const unknownDate = intl.formatMessage({
    id: 'companyPage.seo.unknownDate',
    defaultMessage: 'tuntemattomana vuonna',
  });

  const description = intl.formatMessage(
    {
      id: 'companyPage.seo.description',
      defaultMessage: '{name} on {year} perustettu yritys.{tol}{turnover} Tiedontäyteiset yritysprofiilit tarjoaa Constle.',
    },
    {
      name,
      year: dateEstablishedString || unknownDate,
      tol: tol?.name ? ` ${tolText}` : '',
      turnover: turnoverString ? ` ${turnoverText}` : '',
    }
  );

  return (
    <Head>
      {/* Title */}
      <title>{title}</title>

      {/* Description */}
      <meta name="description" content={description} />

      {/* This tells Google that /company/id/name is the URL that should be indexed for this page */}
      <link rel="canonical" href={url} />
      {alternates}
    </Head>
  );
};
