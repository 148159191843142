// Note! Only add stuff here that will work in ALL subprojects (api/pro/www)

export const numberPrefixUnitFormat = (
  val: string | number | null | undefined,
  options?: {
    unit?: string;
    longForm?: boolean;
    valuePrefixSpacing?: number;
    valuePrefixSpace?: string;
    prefixUnitSpacing?: number;
    prefixUnitSpace?: string;
    htmlEntities?: boolean;
    locale?: string | null;
    decimals?: number;
    prefixCutLimit?: number;
    precisionZeroes?: boolean;
    htmlUnicodeEscape?: boolean;
    prefixRemap?: { [key: string]: string };
  }
) => {
  if (typeof val === 'string' && parseFloat(val).toString() === val) {
    // is string that is straight convertable to float
    val = parseFloat(val);
  }
  if (typeof val === 'string' && parseInt(val).toString() === val) {
    // is string that is straight convertable to integer
    val = parseInt(val);
  }
  const originalValue = val;
  // defaults
  let optionDefaults = {
    unit: '',
    longForm: false,
    valuePrefixSpacing: 0,
    valuePrefixSpace: ' ',
    prefixUnitSpacing: 1,
    prefixUnitSpace: ' ',
    htmlEntities: false,
    locale: null,
    decimals: 2,
    prefixCutLimit: 800,
    htmlUnicodeEscape: false,
    prefixRemap: {},
  };

  // dirty way to clean options from undefined values
  let newOptions: any = {};
  if (options && typeof options == 'object') {
    for (const [key, val] of Object.entries(options)) {
      if (val !== undefined) newOptions[key] = val;
    }
    options = newOptions;
  }
  // replace defaults with provided values
  const Options = { ...optionDefaults, ...options } as {
    unit: string;
    longForm: boolean;
    valuePrefixSpacing: number;
    valuePrefixSpace: string;
    prefixUnitSpacing: number;
    prefixUnitSpace: string;
    htmlEntities: boolean;
    locale: string | null;
    decimals: number;
    prefixCutLimit: number;
    precisionZeroes: boolean;
    htmlUnicodeEscape: boolean;
    prefixRemap: { [key: string]: string };
  };

  if (val === null || val === undefined || !Number.isFinite(val) || isNaN(typeof val == 'string' ? parseInt(val) : val)) return '';
  if (val === 0) return '0';
  val = parseFloat(val.toString()); // force to number type

  const firstPrefixExponent = -24;
  const prefixExponentStep = 3;

  // prettier-ignore
  const localePrefixLists : any = {
      iso: {
        short: ['y', 'z', 'a', 'f', 'p', 'n', 'µ', 'm',  '', 'k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'], 
        long: ['yocto', 'zepto', 'atto', 'femto', 'pico', 'nano', 'micro', 'milli',  '', 'kilo', 'mega', 'giga', 'tera', 'peta', 'exa', 'zetta', 'yotta']
      },
      finnish: {
        short: ['y', 'z', 'a', 'f', 'p', 'n', 'µ', 'm', '', 't', 'M', 'Mrd', 'B', 'P', 'E', 'Z', 'Y'],
        long: ['jokto', 'tsepto', 'atto', 'femto', 'piko', 'nano', 'mikro', 'milli', '', 'tuh.', 'milj.', 'mrd.', 'bilj.', '', 'trilj.', '', 'kvadr.'],
      },    
  };

  let prefixIndex = 0;
  val = val * Math.pow(10, -firstPrefixExponent);
  while (val + 0.00001 >= Options.prefixCutLimit) {
    val = val / Math.pow(10, prefixExponentStep);
    prefixIndex++;
  }

  // precision
  val = val.toFixed(Options.decimals);

  if (!Options.precisionZeroes) {
    // if value is exactly integer, don't print precision zeroes but just an integer part
    val = val.replace('.' + '0'.repeat(Options.decimals), '');
  }

  const valuePrefixSpace = Options.valuePrefixSpacing ? Options.valuePrefixSpace.repeat(Options.valuePrefixSpacing) : '';
  const prefixUnitSpace = Options.unit && Options.prefixUnitSpacing ? Options.prefixUnitSpace.repeat(Options.prefixUnitSpacing) : '';
  // prefix defaults to ISO prefix list if specified list not found
  const prefixLists =
    Options.locale && localePrefixLists.hasOwnProperty(Options.locale) ? localePrefixLists[Options.locale] : localePrefixLists.iso;

  /* select prefix list from what exists. Preferred priority:
    - exact match 
    - same locale, different prefix length
    - ISO equivalent, same length
    - ISO equivalent, different length
    - no prefix at all if not found
  */
  let prefixList;
  if (prefixLists.short[prefixIndex] != '' && prefixLists.long[prefixIndex] != '') {
    prefixList = Options.longForm ? prefixLists.long : prefixLists.short;
  } else if (prefixLists.short[prefixIndex] != '') {
    prefixList = prefixLists.short;
  } else if (prefixLists.long[prefixIndex] != '') {
    prefixList = prefixLists.long;
  } else if (localePrefixLists.iso.short[prefixIndex] != '' && localePrefixLists.iso.long[prefixIndex] != '') {
    prefixList = Options.longForm ? localePrefixLists.iso.long : localePrefixLists.iso.short;
  } else if (localePrefixLists.iso.short[prefixIndex] != '') {
    prefixList = localePrefixLists.iso.short;
  } else if (localePrefixLists.iso.long[prefixIndex] != '') {
    prefixList = localePrefixLists.iso.long;
  } else {
    prefixList = [];
    // no prefix, we can just return original value
    return originalValue ? originalValue.toString() : '';
  }

  let prefix = prefixList[prefixIndex] != '' ? prefixList[prefixIndex] : '';

  Object.entries(Options.prefixRemap).map(([key, alias]) => {
    if (prefix == key) prefix = alias;
  });

  let out = val + valuePrefixSpace + prefix + prefixUnitSpace + Options.unit;

  if (Options.htmlUnicodeEscape) {
    out = out.replace(/[\u00A0-\u9999<>\&]/gim, (i) => {
      return '&#' + i.charCodeAt(0) + ';';
    });
  }
  return out;
};

export const formatMinMax = (minMax: { min: number | null; max: number | null } | null, unit = ''): string => {
  if (!minMax) return '';

  const { min, max } = minMax;

  const minMaxSeparator = ' - ';
  const minString = numberPrefixUnitFormat(min, { unit, prefixRemap: { G: 'mrd.' } });
  const maxString = numberPrefixUnitFormat(max, { unit, prefixRemap: { G: 'mrd.' } });

  if (minString === '' && maxString === '') return '';
  if (minString === maxString) return minString;
  if (minString === '' || minString === '0') return '< ' + maxString;
  if (maxString === '' || maxString === '0') return '> ' + minString;
  return minString + minMaxSeparator + maxString;
};

/** Validate email */
export const validateEmail = (email: string | null | undefined) => {
  return email && /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
};
